import React from "react"
import { Button, Dialog , DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core"
import ClearIcon from '@material-ui/icons/Clear';
import { BlueBtn, RedInverseBtn } from "../Styled/StyledButtons"
import styles from '../TeamsManagement/styles.module.css';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { useState , useEffect } from "react";
import { IAgent } from '../../models/AgentSkills/Agent';
import { AgentSkillsActions, IAxiosAgents } from '../../actions/AgentSkillsActions';
import { CustomAccessActions } from "../../actions/CustomAccessActions";

interface IProps {
    toggleHandle: () => void 
    updateTable: () => void
    show: boolean
}

const AddCustomAccess = (props: IProps) => {

    const [users, setAgents] = useState<IAgent[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [datas, setDatas] = useState(users);
    let [selected, setSelected] = useState<number>(0);


    useEffect(() => {
        setDatas(
            users
        );
        getFilteredInfo()
    }, [searchTerm]);
    
    useEffect (() => {
        getFilteredInfo()
        props.updateTable()
    }, []);


    const getFilteredInfo = () => {

        
        
        AgentSkillsActions.getAgents('search='+searchTerm)
        .then((data: IAxiosAgents) => {
            var element: any[] = data.data.data;

    
            console.log(element)
            setAgents(element);
            
        })
    }

    const putCustomAccess = () => {
        if (selected != 0) {
            CustomAccessActions.addCustomAccess(selected).then(() => {
                props.updateTable();
                setSelected(0)
            })
        }
        props.toggleHandle()
    }

    const resetData = () => {
        setSelected(0);
    }

    const getData = (value: number) => {
        setSelected(value);
    }

    return (
        <Dialog open={props.show} onClose={props.toggleHandle}>
            <DialogTitle>
                Add agent to Custom Access
                <IconButton className={mainStyles.floatRight} onClick={props.toggleHandle}>
                    <ClearIcon />
                </IconButton> 
            </DialogTitle>
            <DialogContent className={styles.popup}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>      
                        <>                                      
                            <label className={styles.FormLabel}>Enter data</label>
                            <input 
                                className={styles.FormControl}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm == '' ? searchTerm : searchTerm}
                            />
                            <Button onClick={resetData} color="default" fullWidth={true} variant="outlined">Reset</Button>
                            <div className={styles.divBox}>
                                { 
                                    datas.map((data, index) => (
                                        
                                        <div className={styles.flexBox} key={index}>
                                            <label className={styles.FormLabel}>
                                                {data.first_name +' '+ data.last_name +' '+ data.nickname} <br/>
                                            </label>
                                            <input 
                                                type="radio"
                                                value={data.id} 
                                                onChange={() => getData(data.id)}
                                                className={styles.chechbox}
                                                checked={selected === data.id}
                                                disabled={selected !== 0 && selected !== data.id}
                                            />
                                        </div>
                                    )) 
                                }
                            </div>
                        </>
                        <Grid container spacing={1} >
                            <Grid item xs={6}  >
                                <RedInverseBtn fullWidth={true} onClick={props.toggleHandle}>Cancel</RedInverseBtn>
                            </Grid>
                            <Grid item xs={6}  >
                                <BlueBtn onClick={putCustomAccess} fullWidth={true} >Save</BlueBtn>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AddCustomAccess

