import React from "react";
import { Add } from "@material-ui/icons";
import {BlueInverseBtn} from "../Styled/StyledButtons";
import styles from './styles.module.css';

interface IProps {
    handleAdd: () => void
}

const ControlPanelCustomAccess = (props: IProps) => {
    return (
        <BlueInverseBtn className={styles.addUser} variant="contained" fullWidth={true} onClick={() => props.handleAdd()}><Add />Add User</BlueInverseBtn>
    );
}

export default ControlPanelCustomAccess