import { Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core"
import React, { useEffect } from "react"
import { RedBtn, RedInverseBtn } from "../Styled/StyledButtons"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../TeamsManagement/styles.module.css'
import ClearIcon from '@material-ui/icons/Clear';
import { CustomAccessActions } from "../../actions/CustomAccessActions";
 
interface IProps {
    toggleHandle: () => void
    show: boolean
    dataRemove: number
    updateTable: () => void
}

const RemoveCustomAccess = (props: IProps) => {

    useEffect (() => {
        handleDelete();
        props.updateTable();
    }, []);

    const handleDelete = () => {
        if (props.dataRemove != 0) {
            CustomAccessActions.deleteCustomAccess(props.dataRemove).then(() => {
                props.updateTable();
            })
            props.toggleHandle();
        }
    }

    return (
        <Dialog open={props.show} onClose={props.toggleHandle}>
            <DialogTitle>
                Remove Custom Access?
                <IconButton className={mainStyles.floatRight} onClick={props.toggleHandle}>
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.popup}>
                <Grid container spacing={1}>
                    <Container className={styles.ContainerFlex}>
                        <p className={styles.formtext}>Delete agent (with ID - {props.dataRemove}) from Custom Access?</p>
                    </Container>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} >
                    <Grid item xs={6}  >
                        <RedInverseBtn fullWidth={true} onClick={props.toggleHandle}>Cancel</RedInverseBtn> 
                    </Grid>
                    <Grid item xs={6}  >
                        <RedBtn onClick={handleDelete} fullWidth={true} >Delete</RedBtn>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveCustomAccess