import React from "react";
import styles from './styles.module.css';
import { Button } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import {Box, TableContainer , Table , TableBody , TableHead , TableRow , TableCell, Paper} from "@material-ui/core";
import { CircularProgress, Grid } from "@material-ui/core";
import { useState , useEffect } from "react";
import ControlPanelCustomAccess from "./ControlPanelCustomAccess";
import AddCustomAccess from "./AddCustomAccess";
import RemoveCustomAccess from "./RemoveCustomAccess";
import { Backdrop} from "@material-ui/core";
import { IAgentCustomAccess } from "../../models/CustomAccess/AgentCustomAccess";
import { CustomAccessActions } from "../../actions/CustomAccessActions";

const CustomAccessComponent = () => {

    const [show, setAddFormShow] = useState<boolean>(false);
    const [newshow, setRemoveFormShow] = useState<boolean>(false);
    const [backdrop, setBackdrop] = useState(true);
    const [dataToRemove, setRemove] = useState<number>(0);
    const [users, setAgents] = useState<IAgentCustomAccess[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(users)

    useEffect(() => {
        setFilteredData(
            users.filter((item) =>
                item.first_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setTimeout(() => {
            setBackdrop(false)
        }, 1000);
    }, [searchTerm]);

    const getCustomAccess = () => {
        CustomAccessActions.getCustomAccessList()
            .then((data: any[]) => {
                var element: any[] = data;

                for (var index: number = 0; index < data.length; index++) {
                    element[index] = data[index];
                }
                setAgents(data);
            })
    }

    const showRemoveForm = (dataToRemove: number) => { 
        setRemoveFormShow(true); 
        setRemove(dataToRemove);
    }

    const handleAddUser = () => {
        setAddFormShow(true);
    }
    
    return (
        <>
            <Box className={styles.HeaderBox}>
                <h3 className={styles.HeaderTitle}>Custom Access</h3>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Grid container spacing={1} className={styles.GridContainer}>
                        <Grid item xs={12} md={3}>
                            <div className={styles.filters__search}>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    value={searchTerm == '' ? searchTerm : searchTerm}
                                    className={styles.filters__search__inputSearch}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ControlPanelCustomAccess 
                                handleAdd={handleAddUser}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AddCustomAccess show={show} toggleHandle={() => setAddFormShow(!show)} updateTable={() => getCustomAccess()}/>
            <RemoveCustomAccess show={newshow} toggleHandle={() => setRemoveFormShow(!newshow)} updateTable={() => getCustomAccess()} dataRemove={dataToRemove}/>
            <TableContainer className={styles.TableContainer} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Nick</TableCell>
                            <TableCell>Created by</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell>Updated at</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { users.map((data, index) => (
                            <TableRow key={index}>
                                <TableCell>{data.id}</TableCell>
                                <TableCell>{data.nickname}</TableCell>
                                <TableCell>{data.email}</TableCell>
                                <TableCell>{data.first_name + ' ' + data.last_name}</TableCell>
                                <TableCell>{data.created_by}</TableCell>
                                <TableCell>{data.created_at}</TableCell>
                                <TableCell>{data.updated_at}</TableCell>
                                <TableCell>
                                    <Button onClick={() => showRemoveForm(data.id)} color="secondary"><DeleteForever /></Button>
                                </TableCell>
                            </TableRow>
                            )) 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop className={styles.Backdrop} open={backdrop}>
                <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
                <p className={styles.BackdropProgress}>Loading...</p>
            </Backdrop>
        </>
    )
}

export default CustomAccessComponent