import React from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import "./index.css";

import history from "./components/history";

import LayoutComponent from './components/LayoutComponent/LayoutComponent';
import CustomAccessComponent from './components/CustomAccess/CustomAccessComponent';
import WorkflowsManagementComponent from './components/WorkflowManagement/WorkflowsManagementComponent';
import WorkflowsEditComponent from './components/WorkflowManagement/WorkflowsEditComponent';
import AgentSkillsComponent from './components/AgentSkillsManagement/AgentSkillsComponent';
import QueuesManagmentComponent from './components/QueuesManagement/QueuesManagmentComponent';
import QueuesEditComponent from './components/QueuesManagement/QueuesEditComponent';
import TeamsManagementComponent from './components/TeamsManagement/TeamsManagementComponent';
import AgentsComponent from './components/AgentsComponent/AgentsComponent';

import Error401Component from './components/Errors/Error401Component';
import Error404Component from './components/Errors/Error404Component';
import LocationBrandsComponent from './components/LocationBrandsComponent/LocationBrandsComponent';
import SpamFilterComponent from './components/SpamFilter/SpamFilterComponent';
import TasksComponent from './components/Dashboard/TasksComponent';
import BlockVoipCalls from './components/BlockVoipCalls';

import Whitelist from "./components/Whitelist";
import PhoneHistory from "./components/Whitelist/PhoneHistory";
const routing = (
    <LayoutComponent>
        <Route path="/dashboard" component={TasksComponent} />
        <Route path="/agent-skills/id/:id" component={AgentSkillsComponent} />
        <Route path="/agents" component={AgentsComponent} />
        <Route path="/custom-access" component={CustomAccessComponent} />

    <Route path="/queues" component={QueuesManagmentComponent} />
    <Route path="/queue-create" component={QueuesEditComponent} />
    <Route path="/queue/sid/:sid" component={QueuesEditComponent} />

    <Route path="/workflows" component={WorkflowsManagementComponent} />
    <Route path="/workflow-create" component={WorkflowsEditComponent} />
    <Route path="/workflow/sid/:sid" component={WorkflowsEditComponent} />

    <Route path="/teams" component={TeamsManagementComponent} />
    <Route
      path="/location-brands-settings"
      component={LocationBrandsComponent}
    />
    <Route path="/block-voip" component={BlockVoipCalls} />
    <Route path="/spam-filter" component={SpamFilterComponent} />
    <Route path="/whitelist" component={Whitelist} />
    <Route path="/whitelist-phone/:phoneNumber" component={PhoneHistory} />
  </LayoutComponent>
);

const params = new URLSearchParams(window.location.search);

if (params.get("token") !== undefined && params.get("token") !== null) {
  localStorage.setItem("token", params.get("token"));
  window.location.replace(window.location.origin + window.location.pathname);
}

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route exac path="/401" component={Error401Component} />
      <Route exac path="/404" component={Error404Component} />
      <Switch>{routing}</Switch>
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
