import Api from "./../config/api";


export interface IAxiosCustomAccess {
    data: any[]
}

export class CustomAccessActions {
    static getCustomAccessList(): Promise<any[]> {
        return Api.get("/custom-access")
            .then((res: IAxiosCustomAccess) => { return res.data })
    }
    static addCustomAccess(id: number): Promise<any[]> {
        return Api.put("/custom-access/" + id)
            .then((res: IAxiosCustomAccess) => { return res.data })
    }
    static deleteCustomAccess(id: number): Promise<any[]> {
        return Api.delete("/custom-access/" + id)
            .then((res: IAxiosCustomAccess) => { return res.data })
    }
}